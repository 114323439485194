<template>
  <div>
    <el-row :gutter="20" class="mb-5" type="flex" justify="space-between">
      <el-col :span="12">
        <el-input placeholder="Search by code or name" prefix-icon="el-icon-search"
          v-model="filters.searchString" /></el-col>
      <el-col :span="3.5">
        <el-button type="primary" icon="el-icon-upload" @click="dialogs.excelUpload = true">Upload New Stock</el-button>
      </el-col>
      <el-col :span="3.5">
        <el-tooltip class="item" effect="dark" content="Export IGoal Stock" placement="bottom">
          <el-button type="primary" icon="el-icon-download" @click="_exportIgoalStockWithImage()" />
        </el-tooltip>
      </el-col>
      <el-col :span="4">
        <p class="mt-2">{{ itemsShowing }}</p>
      </el-col>
    </el-row>
    <el-table ref="elTable" :data="filteredData" :row-key="getRowKey" @selection-change="handleSelectionChange"
      style="width: 100%" border size="small" :cell-style="{ padding: '0', height: '20px' }"
      :header-cell-style="{ background: '#f1f1f5', color: 'black', textAlign: 'center' }">
      <el-table-column type="selection" width="35" :reserve-selection="true" />
      <el-table-column width="100">
        <template slot-scope="scope">
          <!-- <v-avatar class="my-2 rounded-lg" size="80" tile @click="previewURL(scope.row.imageLink)">
            <img :src="getImage(scope.row.imageLink)" alt="Loading" />
          </v-avatar> -->
          <el-image :src="getImage(scope.row.imageLink)" style="width: 80px; height: 80px" class="my-2 rounded-lg"
            alt="Loading" lazy fit="fit" @click="previewURL(scope.row.imageLink)" />
        </template>
      </el-table-column>
      <el-table-column property="itemCode" label="Code" align="center" :filters="filters.itemCodes"
        :filter-method="filterHandler" />
      <el-table-column property="itemName" label="Name" width="250" :filters="filters.itemNames"
        :filter-method="filterHandler" />
      <el-table-column property="itemGroup" label="Group" align="center" :filters="filters.itemGroups"
        :filter-method="filterHandler" />
      <el-table-column property="stock" label="Stock" />
      <el-table-column property="costPrice" label="Cost Price" />
      <el-table-column property="totalCost" label="Total Cost" />
      <el-table-column property="" label="Action" align="center">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-picture" @click="[(dialogs.edit = true), (selected.row = scope.row)]"
            size="mini" />
          <!-- <el-button type="primary" icon="el-icon-edit" @click="onEdit(scope.row)" size="mini" /> -->
          <!-- <el-button type="danger" icon="el-icon-delete" @click="onRemove(scope.row)" size="mini" /> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- File Upload Modal -->
    <el-dialog title="Select File to upload" :visible.sync="dialogs.excelUpload" width="30%" center>
      <el-upload class="upload-demo" ref="upload" :auto-upload="false" action="" :file-list="fileList"
        :on-change="handleUploadChange" :on-remove="handleUploadRemove">
        <el-button slot="trigger" size="small" type="primary">Select File</el-button>
        <el-button style="margin-left: 10px" size="small" type="success" @click="submitExcelUpload">Submit</el-button>
      </el-upload>
    </el-dialog>
    <!-- End -->
    <!-- Edit Modal -->
    <el-dialog title="Add Image" :visible.sync="dialogs.edit" width="50%" center>
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-upload class="upload-demo" ref="upload" action="#" list-type="picture-card" :auto-upload="false"
          :file-list="fileList" :on-change="handleUploadChange" :on-remove="handleUploadRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-row>
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-button style="margin-left: 10px" size="small" type="success" @click="submitImageUpload">Submit</el-button>
      </el-row>
    </el-dialog>
    <!-- End -->
  </div>
</template>

<script>
import { getImage } from "../../common/utils";
import { imagePreviewHelper } from "../../config/constants";
import { getAvailableStock, addNewStock, updateImage, exportIgoalStockWithImage } from "./lib";
import { uploadFile, uploadImage } from "../../common/general-api";
export default {
  name: "IGoalStock",
  watch: {
    "dialogs.excelUpload"() {
      this.fileList = [];
    },
  },
  computed: {
    filteredData() {
      let searchString = this.filters.searchString;
      const filteredStock = this.stock.filter(function (item) {
        return (
          item.itemName.toUpperCase().indexOf(searchString.toUpperCase()) >= 0 ||
          item.itemCode.toUpperCase().indexOf(searchString.toUpperCase()) >= 0
        );
      });

      this.$nextTick(() => {
        this.triggerScrollEvent();
      });

      return filteredStock
    },
    itemsShowing() {
      if (!this.isMounted) return;
      const filteredItemsLength = this.$refs.elTable.tableData.length;
      const totalItemsLength = this.stock.length;
      return `Showing ${filteredItemsLength} / ${totalItemsLength} Items`;
    },
  },
  data() {
    return {
      stock: [],
      selected: {
        items: [],
        row: null,
      },
      filters: {
        itemCodes: [],
        itemGroups: [],
        itemNames: [],
        searchString: "",
      },
      dialogs: {
        excelUpload: false,
        edit: false,
      },
      fileList: [],
      isMounted: false,
    };
  },
  methods: {
    getImage: getImage,
    previewURL(imageLink) {
      this.$viewerApi(imagePreviewHelper(imageLink));
    },
    triggerScrollEvent() {
      const scrollEvent = new Event('scroll');
      window.dispatchEvent(scrollEvent);
    },
    async initialize() {
      this.stock = await getAvailableStock();
      this.setFilters();
    },
    setFilters() {
      this.filters.itemCodes = [...new Set(this.stock.map((item) => item.itemCode))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.itemGroups = [...new Set(this.stock.map((item) => item.itemGroup))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.itemNames = [...new Set(this.stock.map((item) => item.itemName))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    handleSelectionChange(item) {
      this.selected.items = item;
    },
    filterHandler(value, row, column) {
      return this.resolve(column["property"], row) === value;
    },
    resolve(path, obj) {
      return path.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    getRowKey(row) {
      return row.id;
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleUploadRemove() {
      this.fileList = [];
    },
    async submitExcelUpload() {
      if (this.fileList.length == 0) {
        this.$notify({
          title: "Please select a file for uploading",
          type: "error",
        });
        return;
      }

      try {
        await this.$store.dispatch("setLoading", true);
        this.dialogs.excelUpload = false;

        const fileLink = await uploadFile(this.fileList[0].raw, "/IGoal-Stock");

        const res = await addNewStock(fileLink);

        if (res?.data.statusCode == 200) {
          this.$notify({
            title: "New stock has been uploaded successfully",
            text: "Opening stock has been uploaded successfully",
            type: "success",
          });
          await this.initialize();
        }

        await this.$store.dispatch("setLoading", false);
      } catch (error) {
        await this.$store.dispatch("setLoading", false);
        this.$notify({
          title: error.response.data.message,
          text: error.response.data.message,
          type: "error",
        });
      }
    },
    async submitImageUpload() {
      if (this.fileList.length == 0) {
        this.$notify({
          title: "Please select a Image for uploading",
          type: "error",
        });
        return;
      }

      await this.$store.dispatch("setLoading", true);
      this.dialogs.edit = false;

      const imageLink = await uploadImage(this.fileList[0].raw, "/IGoal-Stock-Image");

      const row = this.stock.find((item) => item == this.selected.row);

      try {
        const res = await updateImage(row.id, imageLink);
        console.log(res);
        if (res?.status == 200) {
          this.$notify({
            title: "Image added successfully",
            type: "success",
          });
          row.imageLink = imageLink;
        }
        this.fileList = []
        await this.$store.dispatch("setLoading", false);
      } catch (error) {
        await this.$store.dispatch("setLoading", false);
        this.$notify({
          title: error.response.data.message,
          text: error.response.data.message,
          type: "error",
        });
      }

      await this.$store.dispatch("setLoading", false);
    },
    async _exportIgoalStockWithImage() {
      await this.$store.dispatch("setLoading", true);
      await exportIgoalStockWithImage()
      await this.$store.dispatch("setLoading", false);
      this.$notify({
        title: "Success",
        text: "Downloaded Successfully.!",
        type: "success",
      });
    },
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.initialize();
    this.isMounted = true;
    await this.$store.dispatch("setLoading", false);
  },
};
</script>

<style>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
</style>
